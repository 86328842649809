<template>
  <keep-alive>
    <v-container
      fluid
      class="d-flex flex-column h-100 pa-0 min-height-inherit max-width-1500"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit max-width-1500">
        <volunteer-left-section class="hidden-sm-and-down" />
        <volunteers-section />
        <volunteer-right-section class="hidden-md-and-down" />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapState } from "vuex";
import VolunteerLeftSection from "../VolunteerLeftSection.vue";
import VolunteerRightSection from "../VolunteerRightSection.vue";
import VolunteersSection from "./VolunteerHoursSection.vue";
export default {
  components: {
    VolunteerLeftSection,
    VolunteerRightSection,
    VolunteersSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      verifyPhoneDialog: false,
      completeProfileDialog: false,
      causeDialog: false,
      interestsDialog: false,
      availabilitiesDialog: false,
      congratesDialog: false,
      notifications: [],
      errorNotifications: [],
    };
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
};
</script>
<style scoped></style>
