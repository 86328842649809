<template>
  <div class="feed-body">
    <div class="feed-contents">
      <v-row style="width: 100%">
        <v-col v-for="volunteer in volunteers" :key="volunteer._id" cols="6">
          <volunteer-card :volunteer="volunteer" />
        </v-col>
      </v-row>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
      <div v-else-if="volunteers.length == 0" class="mt-10 white--text">
        No matched volunteers
      </div>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VolunteerCard from "./VolunteerCard.vue";
export default {
  components: { VolunteerCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
    };
  },
  methods: {
    ...mapActions("volunteer", {
      fetchAllVolunteers: "fetchAllVolunteers",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreVolunteers() {
      this.loadingMore = true;
      this.loadMore({ skip: this.volunteers.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    goToProfile(id) {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id },
      });
    },
  },
  computed: {
    ...mapGetters("volunteer", {
      volunteers: "getAllVolunteers",
    }),
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchAllVolunteers()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
